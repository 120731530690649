export default {
    'welcome': 'Welcome',
    'home': 'Home',
    'dashboard.title': 'Dashboard',
    'dashboard.analysis': 'Analysis',
    'dashboard.monitor': 'Monitor',
    'dashboard.workplace': 'Workplace',
    'form.title': 'Form',
    'form.basic-form': 'Basic Form',
    'form.step-form.title': 'Step Form',
    'form.step-form.info': 'Step Form(write transfer information)',
    'form.step-form.confirm': 'Step Form(confirm transfer information)',
    'form.step-form.result': 'Step Form(finished)',
    'form.advanced-form': 'Advanced Form',
    'list.title': 'List',
    'list.table-list': 'Search Table',
    'list.basic-list': 'Basic List',
    'list.card-list': 'Card List',
    'list.search-list.title': 'Search List',
    'list.search-list.articles': 'Search List(articles)',
    'list.search-list.projects': 'Search List(projects)',
    'list.search-list.applications': 'Search List(applications)',
    'profile.title': 'Profile',
    'profile.basic': 'Basic Profile',
    'profile.advanced': 'Advanced Profile',
    'result.title': 'Result',
    'result.success': 'Success',
    'result.fail': 'Fail',
    'exception.title': 'Exception',
    'exception.not-permission': '403',
    'exception.not-find': '404',
    'exception.server-error': '500',
    'exception.trigger': 'Trigger',
    'account.title': 'Account',
    'account.center': 'Account Center',
    'account.settings': 'Account Settings',
    'account.trigger': 'Trigger Error',
    'account.logout': 'Logout',
    'dynamicForm': 'Dynamic Form',
    'actualDemand': 'Spot Demand',
    'logicflow': 'Flow',
    '子账号': 'Sub Account',
    'makeChart': 'Make Chart'
}
