export default {
    'login.username.title': '用户名',
    'login.passnord.title': '密码',
    'login.username.placeholder': '请输入您的账号',
    'login.password.placeholder': '请输入您的密码',
    'login.message-invalid-credentials': '账户或密码错误',
    'login.message-invalid-verification-code': '验证码错误',
    'login.tab-login-credentials': '账户密码登录',
    'login.tab-login-mobile': '手机号登录',
    'login.mobile.placeholder': '手机号',
    'login.mobile.verification-code.placeholder': '验证码',
    'login.remember-me': '自动登录',
    'login.forgot-password': '忘记密码',
    'login.sign-in-with': '其他登录方式',
    'login.signup': '注册账户',
    'login.login': '登录',
    'login.generateMenu': '正在获取菜单,请稍等...',
    'register.register': '注册',
    'register.email.placeholder': '账号',
    'register.password.placeholder': '密码 (请至少输入 6 个字符)',
    'register.password.popover-message': '密码 (请至少输入 6 个字符)',
    'register.confirm-password.placeholder': '确认密码',
    'register.get-verification-code': '获取验证码',
    'register.sign-in': '使用已有账户登录',
    'register-result.msg': '你的账户：{email} 注册成功',
    'register-result.activation-email':
        '激活邮件已发送到你的邮箱中，邮件有效期为24小时。请及时登录邮箱，点击邮件中的链接激活帐户。',
    'register-result.back-home': '返回首页',
    'register-result.view-mailbox': '查看邮箱',
    'email.required': '请输入账号！',
    'email.wrong-format': '邮箱地址格式错误！',
    'username.required': '请输入帐户名',
    'password.required': '请输入密码！',
    'password.twice.msg': '两次输入的密码不匹配!',
    'password.strength.msg': '密码强度不够 ',
    'password.strength.strong': '强度：强',
    'password.strength.medium': '强度：中',
    'password.strength.low': '强度：低',
    'password.strength.short': '强度：太短',
    'confirm-password.required': '请确认密码！',
    'phone-number.required': '请输入正确的手机号',
    'phone-number.wrong-format': '手机号格式错误！',
    '用户': '用户',
    密码: '密码',
    '创建时间': '创建时间',
    '操作': '操作',
    '改密': '改密',
    '新建': '新建',
    '子账号': '子账号',
    提交: '提交',
    注册码: '注册码',
    'verification-code.required': '请输入验证码！'
}
